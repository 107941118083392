@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: Futura, Trebuchet MS, Arial, sans-serif;
  }

  h1 {
    @apply font-extrabold 2xl:text-8xl xl:text-7xl lg:text-6xl md:text-5xl sm:text-4xl text-3xl;
  }

  h2 {
    @apply font-semibold lg:text-4xl md:text-3xl text-2xl mb-2;
  }

  h3 {
    @apply font-bold text-xl;
  }

  h4 {
  }

  p {
    @apply my-4 md:text-lg text-base leading-relaxed;
  }
}

@layer utilities {
  .wrapper {
    @apply py-10 md:py-20 px-4;
  }

  .container {
    @apply mx-auto px-4 md:px-8 my-4 md:my-0;
  }

  .container-header {
    @apply text-center mb-8;
  }

  .center-xy {
    @apply flex items-center justify-center;
  }
}
